import "./newsletter.css";

class Newsletter extends HTMLElement {
  constructor() {
    super();

    const input = this.querySelector(".newsletter__email-input");
    const checkbox = this.querySelector(".newsletter-checkkbox");

    if (input && checkbox) {
      input.addEventListener("keyup", this.handleKeyup.bind(this));
      checkbox.addEventListener("change", this.handleCheckbox.bind(this));
    }
  }

  handleKeyup(e) {
    const checkbox = this.querySelector(".newsletter-checkkbox");
    const submitBtn = this.querySelector(".newsletter__submit-btn");
    const errorEl = this.querySelector(".newsletter-error-message");

    const value = e.target.value;
    var expr =
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

    if (!expr.test(value)) {
      errorEl.innerHTML = "Invalid email address.";
      checkbox.disabled = true;
      checkbox.checked = false;
      submitBtn.disabled = true;
    } else {
      errorEl.innerHTML = "";
      checkbox.disabled = false;
    }

    if (value == null || value == "") {
      errorEl.innerHTML = "";
      checkbox.disabled = true;
      checkbox.checked = false;
    }
  }

  handleCheckbox() {
    if (this.querySelector(".newsletter-checkkbox").checked == true) {
      this.querySelectorAll(".newsletter__submit-btn").forEach(
        (btn) => (btn.disabled = false)
      );
    } else {
      this.querySelectorAll(".newsletter__submit-btn").forEach(
        (btn) => (btn.disabled = true)
      );
    }
  }
}

customElements.define("newsletter-section", Newsletter);
